/*
    NAV STYLES
*/

ul.nav-ul {
    list-style: none;
    width: 100vw;
    margin: 0;
    padding: 0;
    text-align: center;
    background-color: var(--color-accent);
    font-size: 1.5rem;
    box-shadow: 0px .25rem .5rem black;
}

ul.nav-ul li {
    padding: .75rem;
    border: 1px solid var(--color-accent-3);
    width: calc(100vw);
}

ul.nav-ul li:hover {
    background-color: var(--color-accent-2);
}

ul.nav-ul li a {
    text-decoration: none;
    font-family: var(--font-main);
    color: var(--color-font-3);
    width: calc(100vw);
}

