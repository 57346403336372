/*
    BODY STYLES
*/

body {
  margin: 0;
  padding: 0;
  background-color: var(--color-main);
  font-family: var(--font-main);
}

/*
  SECTION STYLES
*/

section {
  background-color: var(--color-main-2);
  margin: .5rem;
  padding: 1rem;
  border-radius: 1rem;
}

h2.card-header {
  padding: .5rem 1rem;
}

/*
  TABLE STYLES
*/

table {
  color: var(--color-font);
  margin: .5rem 0;
  width: calc(100%);
}

thead {
  font-weight: bold;
}

tr:nth-child(even) {
  background-color: var(--color-main);
}

td {
  text-align: left;
  padding: .25rem .5rem;
}

/*
    FORM STYLES
*/

form.search-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

input.search-bar {
  font-family: var(--font-main);
  width: calc(100%);
  height: 3rem;
  font-size: 1.25rem;
  padding: .5rem 1rem .5rem 1rem;
  border-style: none;
  border-radius: 1rem;
}

.select-bar {
  font-family: var(--font-main);
  width: calc(100% - 3rem);
  height: 3rem;
  font-size: 1.25rem;
  padding: .5rem 1rem .5rem 1rem;
  border-style: none;
  border-radius: 1rem 0 0 1rem;
}

img.search-button {
  width: 2rem;
  height: 2rem;
}

#nothing-alert {
  font-family: var(--font-accent);
  font-size: 1.5rem;
  color: var(--font-color);
  margin: 1rem .5rem 0 .5rem;
  text-align: center;
}

#generator {
  display: flex;
  flex-direction: row;
}

button {
  padding: .5rem;
  height: 3rem;
  background-color: var(--color-accent);
  border-style: none;
  border-radius: 0 1rem 1rem 0;
}

#results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#results ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

#results ul li {
  padding: .5rem;
}