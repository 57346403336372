/*
    HEADER STYLES
*/

.Header {
    height: 4rem;
    background-color: hsl(225, 10%, 10%);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.menu-button {
    background-color: var(--color-accent);
    height: 3rem;
    width: 3rem;
    margin: .5rem;
    padding: .5rem;
    border-radius: 2rem;
}

.menu-button:hover {
    background-color: var(--color-accent-2);
}