form.search-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  input.search-bar {
    font-family: var(--font-main);
    width: calc(100%);
    height: 3rem;
    font-size: 1.25rem;
    padding: .5rem 1rem .5rem 1rem;
    border-style: none;
    border-radius: 1rem;
  }
  
  .select-bar {
    font-family: var(--font-main);
    width: calc(100% - 3rem);
    height: 3rem;
    font-size: 1.25rem;
    padding: .5rem 1rem .5rem 1rem;
    border-style: none;
    border-radius: 1rem 0 0 1rem;
  }
  
  img.search-button {
    width: 2rem;
    height: 2rem;
  }
  
  #nothing-alert {
    font-family: var(--font-accent);
    font-size: 1.5rem;
    color: var(--color-font);
    margin: 1rem .5rem 0 .5rem;
    text-align: center;
  }