@import url('https://fonts.googleapis.com/css2?family=Rokkitt:ital,wght@0,100..900;1,100..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

/*
  UNIVERSAL STYLES
*/

html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

.hidden {
  display: none;
}

/*
  VARIABLE STYLES
*/
:root{
  --color-main: hsl(225, 5%, 10%);
  --color-main-2: hsl(225, 5%, 20%);

  --color-font: hsl(225, 10%, 80%);
  --color-font-2: hsl(210, 40%, 65%);
  --color-font-3: hsl(225, 5%, 10%);
  
  --color-accent: hsl(210, 40%, 65%);
  --color-accent-2: hsl(210, 40%, 80%);
  --color-accent-3: hsl(210, 40%, 50%);

  --font-main: 'Ubuntu', 'Arial', sans-serif;
  --font-accent: 'Rokkitt', serif;
}

/*
  TEXT STYLES
*/

h1, h2, h3, h4, h3, h4, h5, h6 {
  margin: .5rem 0;
  color: var(--color-font-2);
  font-family: var(--font-main);
}

p {
  color: var(--color-font);
  font-family: var(--font-accent);
  margin: .5rem 0;
}

a {
  color: var(--color-accent);
  font-family: var(--font-accent);
}

li {
  color: var(--color-font);
  font-family: var(--font-main);
}